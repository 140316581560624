import React, { useEffect, useState } from 'react';
import styles from "./HomePage.module.css";
import { Heading, Image, ListItem, Text } from '@chakra-ui/react'
import ReactGA from 'react-ga'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import markdownFile from '../assets/homepage.md'
import remarkGfm from 'remark-gfm'


const HomePage = (props) => {
    const [markdown, setMarkdown] = useState("");
    useEffect(() => {
        fetch(markdownFile)
        .then((res) => res.text())
        .then((text) => setMarkdown(text));
    }, []);
    ReactGA.pageview(window.location.pathname + window.location.search);

    const newTheme = {
        h1: props => {
            const { children } = props;
            return (
              <Text fontSize='5xl' mb={6} fontWeight={700}>
                {children}
              </Text>
            );
        },
        h2: props => {
            const { children } = props;
            return (
              <Text fontSize='4xl'mb={5} fontWeight={600}>
                {children}
              </Text>
            );
        },
        h3: props => {
            const { children } = props;
            return (
              <Text fontSize='2xl' mb={3} mt={3} fontWeight={600}>
                {children}
              </Text>
            );
        },
        p: props => {
          const { children } = props;
          return (
            <Text fontSize='xl' mb={2} mt={2}>
              {children}
            </Text>
          );
        },
        li: props => {
            const { children } = props;
            return (
              <ListItem fontSize='xl'  mb = {1} mt = {1}>
                {children}
              </ListItem>
            );
        }
    };
    return (
        <div className = {styles["homepage-container"]}>
            <div className={styles['header-container']}>
                {/* <div className={styles['name-container']}>
                    <Heading as="h1" fontSize='5xl'>Aditya Karad</Heading>
                </div> */}
                
                {/* <div className={styles['image-container']}>
                    <Image boxShadow="xl" src={photo} alt="Aditya Karad" borderRadius="lg"></Image>
                    <p>Here's a picture of me</p>
                </div> */}
            </div>
            <div className={styles["info-container"]}>
                <ReactMarkdown  
                    components={ChakraUIRenderer(newTheme)} 
                    remarkPlugins={[remarkGfm]} 
                    children={markdown}
                    className='markdownElements'
                />
            </div>
        </div>
    );
};

export default HomePage;