import React from 'react';
import styles from './App.module.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import HomePage from './pages/HomePage';
import Navbar from './UI/Navbar';
import {Box, useColorModeValue} from '@chakra-ui/react';
import Projects from './pages/ProjectsPage';
import ReactGA from 'react-ga';

function App() {
	//check if the url is
	if (window.location.hostname === "aditya-karad.web.app" || 
		window.location.hostname === "aditya-karad.firebaseapp.com") {
		window.location.href = 'https://adityakarad.com'; 
	}
	// Google analytics setup
	ReactGA.initialize('UA-217864953-1');
	ReactGA.pageview(window.location.pathname + window.location.search);
	ReactGA.pageview(window.location.pathname + window.location.search);

	return (
		<Box className={styles['app-container']} bg={useColorModeValue('#ffffff50', '#121212')} color={useColorModeValue("#000000", "#FFFFFF")}>
				<Router>
					<Navbar></Navbar>
					<Box className={styles["main-container"]}>
						<Routes>
							<Route path="/" element={<HomePage />} />
							<Route path="/projects" element={<Projects />} />
						</Routes>
					</Box>
				</Router>
			
		</Box>
		
		
		
	);
}

export default App;
