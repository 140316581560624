import React from 'react';
import styles from './Navbar.module.css';
import { NavLink, Link as NormalLink } from 'react-router-dom';
import {
  Container,
  Box,
  Link,
  Stack,
  Heading,
  Flex,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  IconButton,
  useColorModeValue
} from '@chakra-ui/react';

import { HamburgerIcon } from '@chakra-ui/icons'
import ThemeToggleButton from './ThemeToggleButton'
import { BsTwitter, BsGithub, BsLinkedin} from 'react-icons/bs';
import {IoMdMail} from 'react-icons/io';
const socialLinks = [
    {
        icon: <BsTwitter size={22}/>,
        url: "https://twitter.com/itsKarad",
    },
    {
        icon: <BsGithub size={22}/>,
        url: "https://github.com/itsKarad",
    },
    {
        icon: <BsLinkedin size={22}/>,
        url: "https://www.linkedin.com/in/itskarad/",
    },
    {
        icon: <IoMdMail size={25}/>,
        url: "mailto:adityakarad28@gmail.com",
    },

]

const Navbar = props => {

  return (
    <div className={styles["navbar-container"]}>
        <Box
            as="nav"
            w="100%"
            bg={useColorModeValue('#ffffff50', '#00000050')}
            css={{ backdropFilter: 'blur(10px)' }}
            zIndex={1}
            >
            <Container
                display="flex"
                p={2}
                maxW="container.md"
                wrap="wrap"
                align="center"
                justify="space-between"
            >
                <Flex align="center" mr={5}>
                <Heading as="h1" size="lg" letterSpacing={'tighter'}>
                    <NormalLink className={styles["navlink"]}  to="/">
                        Aditya Karad
                    </NormalLink>
                </Heading>
                </Flex>

                <Stack
                direction={{ base: 'column', md: 'row' }}
                display={{ base: 'none', md: 'flex' }}
                width={{ base: 'full', md: 'auto' }}
                alignItems="center"
                flexGrow={1}
                mt={{ base: 4, md: 0 }}
                >

                <Box p={2}>
                    <NavLink className={styles["navlink"]} to="/projects" style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })}>
                        📽 Projects
                    </NavLink>
                </Box>
                <Box p={2}>
                    <Link className={styles["navlink"]} target="_blank" href="https://bytesandlogs.me/">
                        🖊 Bytes & Logs
                    </Link>
                </Box>
                {
                    socialLinks.map((social) => {
                        return (
                            <Link key={social.url} p={2} target="_blank" href = {social.url}>
                                {social.icon}
                            </Link>
                        )
                    })
                }

                </Stack>

                <Box flex={1} align="right">
                <ThemeToggleButton />

                <Box ml={2} display={{ base: 'inline-block', md: 'none' }}>
                    <Menu isLazy id="navbar-menu">
                    <MenuButton
                        as={IconButton}
                        icon={<HamburgerIcon />}
                        variant="outline"
                        aria-label="Options"
                    />
                    <MenuList>
                        
                        <NavLink className={styles["navlink"]} to="/projects">
                            <MenuItem as={Link}>
                                📽 Projects
                            </MenuItem>
                        </NavLink>
                        
                        <Link className={styles["navlink"]} target="_blank" href="https://bytesandlogs.me/">
                            <MenuItem as={Link}>
                                🖊 Bytes & Logs
                            </MenuItem>
                        </Link>
                        <MenuItem>
                        {
                            socialLinks.map((social) => {
                                return (
                                    <Link key={social.url} p={2} target="_blank" href = {social.url}>
                                        {social.icon}
                                    </Link>
                                )
                            })
                        }
                        </MenuItem>
                    </MenuList>
                    </Menu>
                </Box>
                </Box>
            </Container>
        </Box>
    </div>
    
  )
}

export default Navbar